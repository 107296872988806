import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";

const AppLoader = lazy(() => {
  const isMotor = process.env.REACT_APP_BUILD_TARGET === "motor";
  return isMotor ? import("./motor_src") : import("./navi_src");
});

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Suspense>
        <AppLoader />
      </Suspense>
    </React.StrictMode>,
  );
}
